import { BaseService } from '../BaseService';

class DashboardMessagesService extends BaseService {
  getLeftSideUsers() {
    return this.get(`left-side-users`);
  }
  getMessagesList(messageHeaderId) {
    return this.get(`${messageHeaderId}/list`);
  }
  async postMessages(data) {
    return this.post(``, data);
  }
}

export default new DashboardMessagesService('/dashboard/messages');
